import m from 'mithril'
import {
    Button,
    CellMargin,
    FieldMoney,
    FieldSelect,
    FieldProduct,
    FieldText,
    Memo,
} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    invalid_fields,
    invalid_fields_format,
} from '@bitstillery/common/lib/validation'
import {focus_field} from '@bitstillery/common/lib/utils'

import {OrderSummary} from '../components/order_summary'

import {calculate_margin_percentage} from '@/lib/utils'
import {collection, fetch_lots, select_row} from '@/sales/orders/view/lib/collection_sellable'
import {context, EntitySOI, EntityType, methods} from '@/sales/orders/view/lib/context'

export function max_number_of_cases(entity: EntitySOI) {
    let number_of_cases_available = entity.number_of_cases_available

    // A sellable item groups countries; a SOI does not.
    if (context.data.countries_of_origin) {
        const sellable_cases_available = context.data.countries_of_origin.find(i => i.country_of_origin === entity.country_of_origin)?.number_of_cases_available
        if (sellable_cases_available) {
            number_of_cases_available = sellable_cases_available
        }
    }

    if (context.data.lots.length > 0) {
        const lot_cases_available = context.data.lots.find(i => i.artkey === entity.item_artkey)?.number_of_cases_available
        if (lot_cases_available) {
            number_of_cases_available = lot_cases_available
        }
    }

    return number_of_cases_available
}

export class ContextStockItem extends MithrilTsxComponent<unknown> {

    view(_vnode: m.Vnode<unknown, this>) {
        const entity = context.data.entities[context.data.entity_type] as EntitySOI
        const $v = context.$v[context.data.entity_type] as typeof $v.SOI | typeof $v.SOTI

        const invalid = invalid_fields($v)
        const hidden_alert = entity.hidden_by_rule

        return <div className="c-manage-stock-item sales orders js-manage-stock-item">
            <FieldProduct
                collection={collection}
                context={context}
                entity={entity}
                entity_type={EntityType.SOI}
                filter={collection.filters.search}
                linked={entity.artkey || entity.source_artkey} // Sellable or entity selected
                locked={entity.artkey} // Lock existing entities
                select_row={async(row) => {
                    await Promise.all([
                        select_row(row),
                        fetch_lots(row.case_artkey),
                    ])
                }}
                tabindex={12}
            />

            <Memo alert={hidden_alert} className="mb-3" />

            {entity.reference && context.data.countries_of_origin && context.data.countries_of_origin.length > 1 && <FieldSelect
                help="There are multiple countries of origin for this product."
                label="Country of origin"
                model={[entity, 'country_of_origin']}
                placeholder="From any origin..."
                tabindex={13}
                onchange={(country_of_origin) => {
                    // Will reset the current lot selection; in order to stay in sync.
                    fetch_lots(entity.case_artkey, country_of_origin)
                }}
                options={context.data.countries_of_origin.map((i:any) => ({
                    label: `${i.country_of_origin} - ${i.number_of_cases_available} cases`,
                    value: i.country_of_origin,
                }))}
            />}

            {entity.reference && <FieldSelect
                disabled={context.data.lots.length <= 1}
                help={(() => {
                    if (context.data.lots.length === 0) {
                        return 'No warehouse lots available for this product.'
                    } else if (context.data.lots.length === 1) {
                        return 'Exactly one warehouse lot available for this product.'
                    } else {
                        return `There are ${context.data.lots.length} warehouse lots available for this product.`
                    }
                })()}
                label={context.data.lots.length > 1 ? `Warehouse lot (${context.data.lots.length} lots)` : 'Warehouse lot'}
                model={[entity, 'item_artkey']}
                options={context.data.lots.map((i) => ({
                    label: `${i.lot} - ${i.number_of_cases_available} cases; EUR ${i.euro_was_bought_for}`,
                    value: i.artkey,
                }))}
                placeholder={context.data.lots.length === 1 ? undefined : 'From any lot, oldest lot first...'}
                tabindex={context.data.lots.length <= 1 ? 14 : -1}
            />}

            <FieldMoney
                currency={[context.data.sales_order, 'was_sold_in']}
                disabled={context.data.entity_type !== 'SOI' && !context.data.entities.SOI.item_artkey}
                help={entity.reference ? 'The price per case to sell this item for in this order.' : 'Do NOT update the price without the customer\'s approval.'}
                label={context.data.sales_order.includes_excise ? 'Price per case (incl. excise)' : 'Price per case'}
                model={[entity, 'price_per_case']}
                placeholder="Price per case"
                tabindex={15}
                validation={$v.price_per_case}
            />

            {!!(entity.reference || entity.artkey) && <CellMargin
                className="mb-1 fl"
                label="Margin:"
                value={(() => {
                    return calculate_margin_percentage({
                        price_per_case: +entity.price_per_case,
                        excise_per_case: entity.excise_per_case ? +entity.excise_per_case : 0,
                        number_of_cases: entity.number_of_cases,
                        item: {euro_was_bought_for: +entity.euro_was_bought_for},
                    }, context.data.sales_order)
                })()}
            />}

            <FieldText
                disabled={context.data.entity_type !== 'SOI' && !context.data.entities.SOI.item_artkey}
                help="Number of cases to add to the sales order."
                label="Quantity (case)"
                max={max_number_of_cases(entity)}
                min={entity.minimum_order_quantity || 1}
                model={[entity, 'number_of_cases']}
                placeholder={(() => {
                    let placeholder_str = ''
                    if (entity.minimum_order_quantity) {
                        placeholder_str += `Minimum: ${entity.minimum_order_quantity} `
                    }
                    if (max_number_of_cases(entity)) {
                        placeholder_str += `Maximum: ${max_number_of_cases(entity)}`
                    }
                    return placeholder_str || 'Number of cases...'
                })()}
                type="number"
                tabindex={16}
                validation={$v.number_of_cases}
            />

            <Button
                className="btn-submit"
                disabled={invalid.length}
                icon="save"
                onclick={async() => {
                    const country: string | undefined = entity.country_of_origin && entity.country_of_origin !== '' ? entity.country_of_origin : undefined
                    const item_artkey: number | undefined = entity.item_artkey && entity.item_artkey !== '' ? entity.item_artkey : undefined
                    await methods.upsert_entity(EntityType.SOI, {
                        case_artkey: entity.case_artkey,
                        number_of_cases: entity.number_of_cases,
                        item_artkey: item_artkey,
                        country_of_origin: country,
                        price_per_case: entity.price_per_case,
                    })
                    focus_field(12)
                }}
                text={context.data.entity_artkey ? `Update ${entity.title}` : `Add ${entity.title}`}
                tabindex={17}
                tip={() => invalid_fields_format(invalid_fields($v), 'tip')}
                type="success"
            />
            {!entity.artkey && <OrderSummary />}
        </div>
    }
}
