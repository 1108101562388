import m from 'mithril'
import {$s} from '@bitstillery/common/app'
import {classes, unique_id} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {tip} from '@bitstillery/common/components'
import {logger} from '@bitstillery/common/app'

import {Icon} from '../icon/icon'
import {Spinner} from '../spinner/spinner'

export interface ButtonAttrs {
    active?: boolean
    active_exact?: boolean
    className?: string
    disabled?: boolean

    id?: string
    icon?: string
    loading?: boolean
    link?: string
    /** Url to route back to when clicking an active button */
    replace_history?: boolean
    onclick: (...args) => void
    /** Always triggers a full page re-render */
    refresh?: boolean
    size?: string
    target?: string
    type?: string
    tip?: string | {(): string}
    tip_config?: {}
    text?: string
    variant?: 'action' | 'link' | 'toggle'
}

export class Button extends MithrilTsxComponent<ButtonAttrs> {

    id = unique_id(8, false)
    tippy:any

    elements(vnode: m.Vnode<ButtonAttrs>) {
        const elements: m.Children[] = []
        if (vnode.attrs.icon && vnode.attrs.loading) elements.push(m(Spinner))
        if (vnode.attrs.icon && !vnode.attrs.loading) {
            elements.push(<Icon size={vnode.attrs.size} name={vnode.attrs.icon} type="vnode.attrs.type" />)
        }
        if (vnode.attrs.text && vnode.attrs.variant !== 'toggle') {
            elements.push(m('span', vnode.attrs.text))
        }

        if (vnode.children) {
            if (Array.isArray(vnode.children)) {
                vnode.children.forEach(child => elements.push(child))
            } else {
                elements.push(vnode.children)
            }
        }
        return elements
    }

    onremove(vnode: m.Vnode<ButtonAttrs>) {
        if (vnode.attrs.tip && this.tippy) {
            this.tippy.destroy()
        }
    }

    oncreate(vnode: m.Vnode<ButtonAttrs>) {
        if (vnode.attrs.tip) {
            let selector
            if (vnode.attrs.variant === 'link') {
                selector = document.querySelector(`#tip-${this.id}`)
            } else {
                selector = document.querySelector(`#c-button-${this.id}`)
            }

            const tip_config:any = {
                allowHTML: true,
                animation: 'scale',
                appendTo: document.body,
                content: this.update_tip(vnode),
                delay: 500,
                inertia: true,
                interactive: true,
                onTrigger: (instance) => {
                    instance.setProps({content: this.update_tip(vnode)})
                },
                touch: 'hold',
                zIndex: 10000000000000000,
            }

            if (vnode.attrs.tip_config) {
                Object.assign(tip_config, vnode.attrs.tip_config)
            }
            this.tippy = tip(selector, tip_config)
        }
    }

    update_tip(vnode: m.Vnode<ButtonAttrs>) {
        if (typeof vnode.attrs.tip === 'function') {
            return vnode.attrs.tip()
        } else {
            return vnode.attrs.tip
        }
    }

    view(vnode:m.Vnode<ButtonAttrs>) {
        const attrs:any = {
            className: classes('c-button', vnode.attrs.className,
                `type-${vnode.attrs.type ? vnode.attrs.type : 'default'}`,
                `size-${vnode.attrs.size ? vnode.attrs.size : 'd'}`,
                `variant-${vnode.attrs.variant ? vnode.attrs.variant : 'action'}`, {
                    active: (() => {
                        if (typeof vnode.attrs.active === 'boolean') {
                            return vnode.attrs.active
                        }
                        if (vnode.attrs.link) {
                            if (vnode.attrs.active_exact) {
                                return m.route.get() === vnode.attrs.link
                            }
                            return m.route.get().startsWith(vnode.attrs.link)
                        }
                    })(),
                    disabled: vnode.attrs.disabled,
                },
            ),
            id: `c-button-${vnode.attrs.id ?? this.id}`,
            onclick: async(e, ...args) => {
                if (vnode.attrs.disabled) {
                    e.preventDefault()
                }

                if (vnode.attrs.link && !vnode.attrs.disabled && vnode.attrs.target !== '_blank') {
                    e.preventDefault()
                    e.stopPropagation()
                    const urlpath = m.parsePathname(m.route.get())
                    const url_params = Object.fromEntries(new URLSearchParams(urlpath.params))
                    const {path: link_path} = m.parsePathname(vnode.attrs.link)
                    const ctrl_down = e.ctrlKey

                    if (vnode.attrs.refresh) {
                        logger.debug(`[button] refresh route: ${vnode.attrs.link}`)
                        $s.env.key = Date.now()
                        m.route.set(vnode.attrs.link)
                    } else {
                        if (vnode.attrs.active) {
                            if (link_path === $s.env.uri) {
                                logger.debug(`[button] same route; refresh: ${vnode.attrs.link}`)
                                m.route.set(vnode.attrs.link)
                                $s.env.key = Date.now()
                            } else {
                                logger.debug(`[button] to new route: ${vnode.attrs.link}`)
                                if (ctrl_down) {
                                    window.open(`#!${vnode.attrs.link}`)
                                } else {
                                    m.route.set(vnode.attrs.link)
                                }
                            }
                        } else {
                            if (vnode.attrs.replace_history) {
                                // Prevents poluting the history state.
                                m.route.set(vnode.attrs.link, url_params, {replace: vnode.attrs.replace_history})
                            } else {
                                if (ctrl_down || vnode.attrs.target === '_blank') {
                                    window.open(`#!${vnode.attrs.link}`)
                                } else {
                                    m.route.set(vnode.attrs.link)
                                }
                            }
                        }
                    }
                }

                if (vnode.attrs.onclick && !vnode.attrs.disabled) {
                    vnode.attrs.onclick(e, ...args)
                }
            },
            tabindex: vnode.attrs.tabindex,
        }

        if (vnode.attrs.key) {
            attrs.key = vnode.attrs.key
        }
        if (vnode.attrs.link) {
            if (vnode.attrs.link.startsWith('mailto:') || vnode.attrs.link.startsWith('tel:') || vnode.attrs.link.startsWith('http')) {
                attrs.href = vnode.attrs.link
                attrs.target = '_blank'
            } else {
                if (vnode.attrs.target) {
                    attrs.target = vnode.attrs.target
                }
                attrs.href = `/#!${vnode.attrs.link}`
            }
        }
        const elements = [
            m(vnode.attrs.link ? 'a' : 'button', attrs, this.elements(vnode)),
        ]

        if (vnode.attrs.variant === 'link' && vnode.attrs.tip) {
            elements.push(
                <Icon
                    className="tip"
                    id={`tip-${this.id}`}
                    name="info"
                    size="s"
                    type="info"
                />,
            )
        }

        return elements
    }
}
