import m from 'mithril'
import {to_specs} from '@bitstillery/common/lib/specs'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Country,
    PanelFilters,
    RowActionDelete,
} from '@bitstillery/common/components'
import {api, notifier} from '@bitstillery/common/app'
import {url_query_string} from '@bitstillery/common/lib/utils'
import {proxy} from '@bitstillery/common/lib/proxy'

import {ProductAudit} from '@/market/pricelists/components/product_audit'
import {CollectionSplslSuggestions} from '@/market/pricelists/view/lib/collection_splsl_suggestions'
import {context, EntityType} from '@/market/pricelists/view/lib/context'

export const collection = new CollectionProxy()

export const columns = [
    {
        name: 'Product',
        render: (row) => {
            const country = row.country_of_origin ? row.country_of_origin : row.default_country_code
            return <div className="td-group">
                <span className="header">
                    {country && <Country
                        className="mr-05"
                        country_code={country}
                    />}
                    {row.product_name}
                </span>
                {(() => {
                    const item = {
                        bottle_alcohol_percentage: row.alcohol_percentage,
                        bottle_refill_status: row.refill_status,
                        bottle_volume: row.volume,
                        case_number_of_bottles: row.number_of_bottles_per_case,
                        case_gift_box_type: row.gift_box_type,
                        case_customs_status: row.customs_status,
                    }

                    return <span className="details">{to_specs(item)}</span>
                })()}
            </div>
        },
    },
]

export class CollectionSplsl extends MithrilTsxComponent<any> {

    data = proxy({
        loading: {
            ai: false,
        },
    })

    async oninit() {
        await collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: `discover/supplier-price-lists/${context.data.root_artkey}/source-lines/collection-view`,
            },
        })
        collection.events.once('state_items_updated', () => {
            if (context.data.entity_type === EntityType.SPLSL && !context.data.entity_artkey) {
                // Route to the first entity in the collection (Trader workflow preference) on page enter.
                m.route.set(`/market/pricelists/${context.data.root_artkey}/view/unresolved/${EntityType.SPLSL}/${collection.state.items[0].artkey}${url_query_string()}`)
            }
        })
    }

    view() {
        return [
            <PanelFilters collection={collection}/>,
            <CollectionView mode="table">
                <CollectionHeader collection={collection} columns={columns}/>
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    on_row_click={(row) => {
                        if (context.data.entity_type === EntityType.SPLSL && context.data.entity_artkey === row.artkey) {
                            context.select_none(collection)
                        } else {
                            context.select_one(collection, row.artkey, EntityType.SPLSL)
                        }
                    }}
                    row_actions={(row) => {
                        const actions = [
                            <Button
                                active={context.data.entity_artkey === row.artkey}
                                disabled={row.is_suppressed}
                                icon="edit"
                                link={(() => {
                                    if (context.data.entity_type === EntityType.SPLSL && context.data.entity_artkey === row.artkey) {
                                        return `/market/pricelists/${context.data.root_artkey}/view/resolve/${url_query_string()}`
                                    }
                                    return `/market/pricelists/${context.data.root_artkey}/view/resolve/${EntityType.SPLSL}/${row.artkey}${url_query_string()}`
                                })()}
                                tip={(() => {
                                    return 'Edit this pricelist item'
                                })()}
                                type="info"
                                variant="toggle"
                            />,
                            <Button
                                icon="search_web"
                                link={`https://www.google.com/search?q=${encodeURIComponent(row.product_name)}`}
                                target="_blank"
                                tip={() => 'Manual Google search for this product name (opens in a new tab)'}
                                variant="toggle"
                            />,
                        ]
                        if (row.is_suppressed) {
                            actions.push(<RowActionDelete
                                row={row}
                                row_delete={async() => {
                                    collection.select_next(row.artkey, true)
                                    await api.post(`discover/supplier-price-lists/${context.data.root_artkey}/source-lines/${row.artkey}/unsuppressed`, {}, true)
                                    notifier.notify('Sourceline is now no longer suppressed', 'warning')
                                    row.is_suppressed = false
                                    collection.reset_query()
                                }}
                                icon={'undo'}
                                tip="Remove sourceline from the ignore list"
                                variant="deactivate"
                            />)
                        } else {
                            actions.push(<RowActionDelete
                                row={row}
                                row_delete={async() => {
                                    collection.select_next(row.artkey)
                                    await api.post(`discover/supplier-price-lists/${context.data.root_artkey}/source-lines/${row.artkey}/suppressed`, {}, true)
                                    notifier.notify('Sourceline is now suppressed', 'warning')
                                    row.is_suppressed = true
                                    collection.reset_query()
                                }}
                                tip="Put sourceline on ignore list"
                                icon={'shieldHalfFull'}
                                variant="deactivate"
                            />)
                        }
                        return actions
                    }}
                    row_detail={(row) => {
                        return [
                            <ProductAudit
                                audit_endpoint={`discover/supplier-price-lists/${context.data.supplier_pricelist.artkey}/source-lines/${row.artkey}/audit-logs`}
                                header={context.data.supplier_pricelist.header_as_list}
                                line_content={row.line_content}
                                source_line={row}
                                type="warning"
                            />,
                            <CollectionSplslSuggestions />,
                        ]
                    }}
                />
            </CollectionView>,
        ]
    }

}
