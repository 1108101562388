/** llm:tested */
import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {callAndThen} from '@/api'
import {preventDefault} from '@/_utils'
import {text, textarea, date, time, number} from '@/components/inputs'

interface Timeslot {
    from_date: any
    from_time: any
    till_time: any
}

export class ViewGreenShipment extends MithrilTsxComponent<unknown> {
    sales_order_artkey: number
    sales_order: any
    pickup_warehouse: any
    estimated_number_of_pallets: any
    pickup_timeslot: Timeslot
    drop_off_timeslot: Timeslot
    default_unit_type: any
    number_of_kilos: any
    note: any
    loading_reference: any

    constructor() {
        super()
        this.sales_order_artkey = +(m.route.param('artkey'))
        this.sales_order = window.prop(null)
        this.pickup_warehouse = window.prop(null)
        this.estimated_number_of_pallets = window.prop(null)
        this.pickup_timeslot = {
            from_date: window.prop(''),
            from_time: window.prop(''),
            till_time: window.prop(''),
        }
        this.drop_off_timeslot = {
            from_date: window.prop(''),
            from_time: window.prop(''),
            till_time: window.prop(''),
        }
        this.default_unit_type = window.prop(null)
        this.number_of_kilos = window.prop(null)
        this.note = window.prop(null)
        this.loading_reference = window.prop(null)
    }

    oncreate() {
        const data = {
            sales_order_artkey: this.sales_order_artkey,
        }

        callAndThen('sales.core.get_fast_sales_order_with_items', data, {
            success: (resp: any) => {
                this.sales_order(resp.result)
                this.fetch_pickup_warehouse()
                if (this.sales_order().origin.artkey === 1) { // Top logistics
                    this.pickup_timeslot.from_time('08:00')
                    this.pickup_timeslot.till_time('15:30')
                }
            },
        })

        callAndThen('sales.green.fetch_green_unit_types', {}, {
            success: (resp: any) => {
                this.default_unit_type(resp.default_unit_type)
            },
        })
    }

    fetch_pickup_warehouse() {
        const data = {
            artkey: this.sales_order().origin.artkey,
        }

        callAndThen('destinations.get_destination', data, {
            success: (resp: any) => {
                this.pickup_warehouse(resp.result)
            },
        })
    }

    as_date_or_datetime(date: any, time: any) {
        return time() ? `${date()}T${time()}` : date()
    }

    save() {
        const pickup_from = this.as_date_or_datetime(this.pickup_timeslot.from_date, this.pickup_timeslot.from_time)
        const pickup_till = this.as_date_or_datetime(this.pickup_timeslot.from_date, this.pickup_timeslot.till_time)
        const drop_off_from = this.as_date_or_datetime(this.drop_off_timeslot.from_date, this.drop_off_timeslot.from_time)
        const drop_off_till = this.as_date_or_datetime(this.drop_off_timeslot.from_date, this.drop_off_timeslot.till_time)

        if (drop_off_from > drop_off_till || pickup_from > pickup_till) {
            notifier.notify('Timeslot from must be before till timeslot', 'warning')
            return
        }

        const data: any = {
            artkey: this.sales_order_artkey,
            unit_type_id: this.default_unit_type().id,
            weight_in_kilos: this.number_of_kilos(),
            quantity: this.sales_order().number_of_confirmed_cases,
            estimated_number_of_pallets: this.estimated_number_of_pallets(),
            note: this.note(),
            loading_reference: this.loading_reference(),
        }

        if (this.pickup_timeslot.from_date() !== '') {
            data.pickup_timeslot = {
                from: pickup_from,
                till: pickup_till,
            }
        }
        if (this.drop_off_timeslot.from_date() !== '') {
            data.drop_off_timeslot = {
                from: drop_off_from,
                till: drop_off_till,
            }
        }

        callAndThen('sales.green.create_shipment_at_green', data, {
            success: (resp: any) => {
                notifier.notify(`Created a shipment request at green with reference ${resp.reference}, green: ${resp.green_reference}`, 'info')
                m.route.set(`/sales/orders/${this.sales_order_artkey}/view/manage`)
            },
        })
    }

    view() {
        if (!this.sales_order() || !this.pickup_warehouse() || !this.default_unit_type()) {
            return <Spinner />
        }

        return (
            <div class="c-green-shipment sales orders">
                <form class="flex-form" role="form" onsubmit={preventDefault(() => this.save())}>
                    <div class="fieldset-group">
                        <div class="fieldset">
                            <div class="field-readonly">
                                <div class="key">Pickup</div>
                                <div class="value">
                                    <div>{this.sales_order().origin.name}</div>
                                    <div>{this.pickup_warehouse().street_address}</div>
                                    <div>{this.pickup_warehouse().zip_code} {this.pickup_warehouse().city}</div>
                                    <div>{this.pickup_warehouse().country_code}</div>
                                </div>
                            </div>

                            <div class="field-group">
                                {date(this.pickup_timeslot.from_date, {
                                    label: 'Pickup from',
                                })}
                            </div>

                            <div class="field-group">
                                {time(this.pickup_timeslot.from_time, {
                                    label: 'Pickup from Timeslot',
                                })}
                                {time(this.pickup_timeslot.till_time, {
                                    label: 'Pickup till Timeslot',
                                })}
                            </div>
                        </div>

                        <div class="fieldset">
                            <div class="field-readonly">
                                <div class="key">Drop off</div>
                                <div class="value">
                                    <div>{this.sales_order().destination.name}</div>
                                    <div>{this.sales_order().destination.street_address}</div>
                                    <div>{this.sales_order().destination.zip_code} {this.sales_order().destination.city}</div>
                                    <div>{this.sales_order().destination.country_code}</div>
                                </div>
                            </div>

                            {date(this.drop_off_timeslot.from_date, {
                                label: 'Dropoff from',
                            })}

                            <div class="field-group">
                                {time(this.drop_off_timeslot.from_time, {
                                    label: 'Dropoff from Timeslot',
                                })}
                                {time(this.drop_off_timeslot.till_time, {
                                    label: 'Dropoff till Timeslot',
                                })}
                            </div>
                        </div>
                    </div>

                    <div class="fieldset">
                        {this.sales_order().number_of_confirmed_cases === 0 ? (
                            <div class="field">
                                <div class="illegal-situation">The order does not contain any cases! Transport is not possible.</div>
                            </div>
                        ) : this.sales_order().destination_location !== null ? (
                            <div class="field">
                                <div class="illegal-situation">The destination must be a warehouse. Free format destination is not supported.</div>
                            </div>
                        ) : (
                            <span>
                                <div class="field-readonly">
                                    <div class="key">Number of cases</div>
                                    <div class="value">{this.sales_order().number_of_confirmed_cases} {this.default_unit_type().name}</div>
                                </div>
                                <div class="field">
                                    <label>Loading reference</label>
                                    {text(this.loading_reference, {
                                        required: true,
                                    })}
                                </div>
                                <div class="field">
                                    <label>Number of pallets</label>
                                    {number(this.estimated_number_of_pallets, {
                                        required: true,
                                    })}
                                </div>
                                <div class="field">
                                    <label>Weight (kg)</label>
                                    {number(this.number_of_kilos, {
                                        required: true,
                                        min: 0,
                                    })}
                                </div>
                                <div class="field">
                                    <label>Note</label>
                                    {textarea(this.note)}
                                </div>
                            </span>
                        )}
                    </div>
                    <button class="btn btn-success btn-submit" type="submit">Send to green</button>
                </form>
            </div>
        )
    }
}
