import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'

import {PanelPricelist} from '@/market/pricelists/view/components/panel_pricelist'
import {collection, CollectionSpli} from '@/market/pricelists/view/lib/collection_spli'
import {context} from '@/market/pricelists/view/lib/context'

export class ViewManage extends MithrilTsxComponent<unknown> {

    view(): m.Children {
        return <div className="c-view-manage view-container">
            <CollectionSpli />
            {/* Make sure only one PanelPricelist tab is active at the same time */}
            {context.data.params.tab_id === 'manage' && <PanelPricelist collection={collection} />}
        </div>
    }
}
