import m from 'mithril'
import {
    Button,
    FieldSelect,
} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    invalid_fields,
    invalid_fields_format,
} from '@bitstillery/common/lib/validation'
import {focus_field} from '@bitstillery/common/lib/utils'

import {context, EntityType, EntityVoucher, methods} from '@/sales/orders/view/lib/context'

export class ContextVoucher extends MithrilTsxComponent<unknown> {

    view(_vnode: m.Vnode<unknown, this>) {
        const entity = context.data.entities[context.data.entity_type] as EntityVoucher
        const $v = context.$v.VOUCHER

        const invalid = invalid_fields($v)
        return <div className="c-manage-vouchers sales orders">

            <FieldSelect
                disabled={!context.data.active_vouchers.length}
                help="You can adjust the available vouchers from the offer module."
                label="Voucher"
                model={[context.data.entities.VOUCHER, 'artkey']}
                options={context.data.active_vouchers.map((i) => ({label: i.code, value: i.artkey}))}
                placeholder={context.data.active_vouchers.length ? 'Select a voucher...' : 'No vouchers found...'}
                tabindex={28}
                validation={$v.artkey}
            />

            <Button
                className="btn-submit"
                disabled={invalid.length}
                icon="save"
                onclick={async() => {
                    await methods.upsert_entity(EntityType.VOUCHER, {
                        relation_voucher_artkey: context.data.entities.VOUCHER.artkey,
                    })
                    focus_field(28)
                }}
                tabindex={29}
                text={context.data.entity_artkey ? `Update ${entity.title}` : `Add ${entity.title}`}
                tip={() => invalid_fields_format(invalid_fields($v), 'tip')}
                type="success"
            />
        </div>
    }
}
