import {ClipboardCopy, Country, Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {to_specs} from '@bitstillery/common/lib/specs'

interface CellProductAttrs {
    product_name: string
    number_of_bottles: number
    customs_status: string
    gift_box_type: string | null
    tax_label: string | null
    alcohol_percentage: string
    volume: string
    refill_status: string

    show_details?: boolean
    product_category_name?: string
    article_code?: string
    additional_details?: JSX.Element
    bottle_gtin_code?: string
    country_of_origin?: string

    cases_per_pallet?: number
    cases_per_pallet_layer?: number
}

export class CellProduct extends MithrilTsxComponent<CellProductAttrs> {
    view(vnode: m.Vnode<CellProductAttrs>) {
        const children = [] as m.ChildArray
        children.push(<div className='header'>{vnode.attrs.product_name}<ClipboardCopy text={vnode.attrs.product_name} /></div>)
        if (vnode.attrs.alcohol_percentage && vnode.attrs.volume) {
            children.push(
                <span className='details'>
                    {to_specs({
                        bottle_alcohol_percentage: vnode.attrs.alcohol_percentage,
                        bottle_refill_status: vnode.attrs.refill_status,
                        bottle_volume: vnode.attrs.volume,
                        case_number_of_bottles: vnode.attrs.number_of_bottles,
                        case_gift_box_type: vnode.attrs.gift_box_type || '',
                        case_customs_status: vnode.attrs.customs_status,
                        case_tax_label: vnode.attrs.tax_label,
                    })}
                </span>,
            )
        }
        if (vnode.attrs.bottle_gtin_code) {
            children.push(<span class='details'>{vnode.attrs.bottle_gtin_code}</span>)
        }
        if (vnode.attrs.article_code) {
            children.push(<span class='details'>{vnode.attrs.article_code}</span>)
        }
        if (vnode.attrs.country_of_origin || vnode.attrs.product_category_name) {
            children.push(
                <div className='details'>
                    {vnode.attrs.country_of_origin && <Country country_code={vnode.attrs.country_of_origin} size='xs'/>}
                    {' '}
                    {vnode.attrs.product_category_name}
                </div>,
            )
        }
        if (vnode.attrs.cases_per_pallet || vnode.attrs.cases_per_pallet_layer) {
            children.push(
                <div className='dimension-info'>
                    <Icon name='cpp' size='xs'/>
                    <div className='stat'>
                        {vnode.attrs.cases_per_pallet &&
                            <span>{`${vnode.attrs.cases_per_pallet} cpp`}</span>
                        }
                        {vnode.attrs.cases_per_pallet_layer &&
                            <span>{`${vnode.attrs.cases_per_pallet_layer} cpl`}</span>
                        }
                    </div>
                </div>)
        }

        if (vnode.attrs.additional_details) {
            children.push(vnode.attrs.additional_details)
        }

        return <div className="c-cell-product td-group">
            {children}
        </div>
    }
}
