import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    Button,
    CellBottleAndCase,
    CellProduct,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    Country,
    Icon,
    PanelFilters,
} from '@bitstillery/common/components'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {copy_object, merge_deep} from '@bitstillery/common/lib/utils'
import {reset_validation} from '@bitstillery/common/lib/validation'
import {watch} from '@bitstillery/common/lib/store'
import {api} from '@bitstillery/common/app'
import {format_money_with_symbol} from '@bitstillery/common/lib/format.ts'

import {ManageButtons} from '@/sales/orders/view/manage/components/buttons'
import {context, EntitySOI, EntityType} from '@/sales/orders/view/lib/context'
import {
    GetCountryOfOriginAvailabilitiesResponse,
    GetSalesOrderItemsCollectionViewResponse,
    GetSellableItemCollectionViewResponse,
} from '@/factserver_api/fact2server_api'
import {
    SalesOrderItemType,
    SellableItemFilterType,
} from '@/factserver_api/fact2server_api'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {OfferHistory} from '@/components/market_info/offer_history'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {GetLotAvailabilityResponse} from '@/factserver_api/fact2server_api'

const SOI_STATUS = {
    entity: EntityType.SOI,
    icon: 'purchase',
    tip: 'Choose product from purchase items',
    type: 'info',
}

const SOTI_STATUS = {
    entity: EntityType.SOTI,
    icon: 'market',
    tip: 'Choose product from the market',
    type: 'surface',
}

export const collection: CollectionProxy = new CollectionProxy()

export async function fetch_lots(case_artkey: number, country_of_origin?: string) {
    if (!case_artkey) {
        return
    }
    const entity = context.data.entities[context.data.entity_type] as EntitySOI
    const endpoint = `discover/sales-orders/${context.data.root_artkey}/products/lot-availabilities`
    const filters = {case_artkey} as any
    if (country_of_origin) {
        filters.country_of_origin = country_of_origin
    }
    const {result} = await api.get<GetLotAvailabilityResponse[]>(`${endpoint}?filters=${JSON.stringify(filters)}`)
    context.data.lots.splice(0, context.data.lots.length, ...result)
    if (context.data.lots.length === 1) {
        entity.item_artkey = context.data.lots[0].artkey
    } else {
        entity.item_artkey = '' as any
    }
}

export async function select_row(row) {
    const sellable_item = copy_object(row)
    // A reference_type means what type of sellable item we want to add to the SOTI:
    // - TBO_MARKET requires offer_item_artkey (clientside)
    // - POI requires purchase_order_item_artkey
    // - SPLI requires supplier_price_list_item_artkey
    let reference_type, row_artkey
    if (sellable_item.artkey.includes('-')) {
        reference_type = sellable_item.artkey.split('-')[0]
        row_artkey = sellable_item.artkey.split('-')[1]
    } else {
        reference_type = 'TBO_MARKET'
        row_artkey = sellable_item.artkey
    }

    sellable_item.reference_type = reference_type

    delete sellable_item.artkey
    delete sellable_item.country_of_origin

    if (sellable_item.sellable_item_type !== context.data.entity_type) {
        // E.g. hidden items are not directly bound to a sellable item type.
        const new_sellable_entity = sellable_type(row)
        context.data.entity_type = new_sellable_entity.entity
        m.route.set(`/sales/orders/${context.data.root_artkey}/view/manage/${new_sellable_entity.entity}`)
    }

    const entity: any = context.data.entities[context.data.entity_type]

    if (sellable_item.countries_of_origin) {
        context.data.countries_of_origin.splice(0, context.data.countries_of_origin.length, ...sellable_item.countries_of_origin)
    }
    merge_deep(context.data.entities[context.data.entity_type], sellable_item)

    if (row.sellable_item_type === SellableItemFilterType.Credit) {
        entity.sales_order_item_artkey = row_artkey

        // Default to credit all cases & total value.
        merge_deep(entity, {
            number_of_cases: row.number_of_cases_available,
            number_of_cases_available: row.number_of_cases_available,
            total_value: row.euro_was_bought_for,
        })

        if (row.source_artkey) {
            entity.supplier_price_list_item_artkey = row.source_artkey
        }
    } else {
        reset_validation(context.$v[context.data.entity_type])
        // Notice that we use reference here; not to confuse with the
        // entity's artkey; that's reserved for editing existing SOI's.
        merge_deep(context.data.countries_of_origin, {
            options: row.availabilities,
            selection: '',
        })
        merge_deep(entity, {
            // _artkey: row.artkey,
            euro_was_bought_for: Number(row.euro_was_bought_for),
            // NOTE: Shouldn't Sellable must also have excise_per_case?
            excise_per_case: 0,
            number_of_cases: null,
            lot: row.source_reference,
            price_per_case: row.list_price,
        })

        if (context.data.entity_type === 'SOI') {
            entity.item_artkey = null
            entity.country_of_origin = null
        } else if (context.data.entity_type === 'SOTI') {
            if (reference_type === 'SPLI') {
                entity.supplier_price_list_item_artkey = row_artkey
                entity.purchase_order_item_artkey = null
                entity.offer_item_artkey = null
            } else if (reference_type === 'POI') {
                entity.supplier_price_list_item_artkey = null
                entity.purchase_order_item_artkey = row_artkey
                entity.offer_item_artkey = null
            } else if (reference_type === 'TBO_MARKET') {
                entity.supplier_price_list_item_artkey = null
                entity.purchase_order_item_artkey = null
                entity.offer_item_artkey = row.source_artkey
            }
        }
    }
    collection.select_one(row.artkey)
}

function sellable_type(row: GetSellableItemCollectionViewResponse):any {
    if (row.sellable_item_type === SellableItemFilterType.Purchase) {
        if (row.source_reference && row.source_reference.startsWith('P')) {
            return SOTI_STATUS
        } else {
            return SOI_STATUS
        }
    } else if (row.sellable_item_type === SellableItemFilterType.Stock) {
        return SOI_STATUS
    } else if (row.sellable_item_type === SellableItemFilterType.TBOMarket) {
        return SOTI_STATUS
    } else if (row.sellable_item_type === SellableItemFilterType.Credit) {
        return {
            entity: EntityType.SOCI,
            icon: 'backspace',
            tip: 'Choose product from creditable items',
            type: 'warning',
        }
    }
}

export const columns = [
    {
        name: 'Product',
        render: (row: GetSellableItemCollectionViewResponse) => {
            return <CellProduct
                {...row}
            />
        },
        width: '2fr',
    },
    {
        name: 'Quantity',
        render: (row) => <CellBottleAndCase
            bottle_text={row.number_of_cases_available ? `${row.number_of_bottles * row.number_of_cases_available} btl` : ''}
            case_text={row.number_of_cases_available ? `${row.number_of_cases_available} cs` : ''}
        />,
    },
    {
        name: 'List Price',
        render: (row) => {
            if (row.sellable_item_type === SellableItemFilterType.MarketItem) {
                return <CellBottleAndCase
                    bottle_text={format_money_with_symbol(row.spli_price_per_case / row.number_of_bottles, row.spli_currency)}
                    case_text={format_money_with_symbol(row.spli_price_per_case, row.spli_currency)}
                />
            }
            return <CellBottleAndCase
                bottle_text={format_money_with_symbol(row.list_price_per_bottle, row.currency)}
                case_text={format_money_with_symbol(row.list_price, row.currency)}
            />
        },
    },
    {
        name: 'Source',
        render: (row) => <div className="td-group">
            <div className="header">{row.source}</div>
            <div className="fl fl-g05">{row.availabilities && row.availabilities.map(it =>
                <Country country_code={it.country_of_origin} />)
            }</div>
        </div>,
    },
]

export class CollectionSellable extends MithrilTsxComponent<unknown> {

    watchers:any = []

    async oninit() {
        const transforms = {
            items_queried: async({result}) => {
                const case_artkeys = result.map(it => it.case_artkey).filter(it => it)
                const endpoint = `discover/sales-orders/${context.data.root_artkey}/products/country-of-origin-availabilities?case_artkeys=${case_artkeys.join(',')}`
                const {status_code, result: availability_data} = await api.get<GetCountryOfOriginAvailabilitiesResponse[]> (endpoint)
                if (status_code > 299) {
                    return
                }

                availability_data.forEach(({availabilities, case_artkey}) => {
                    const table_row = result.find(i => case_artkey === i.case_artkey)
                    if (table_row) {
                        table_row.countries_of_origin = availabilities
                    }
                })
                return result
            },
        }
        await collection.init({
            endpoint: {
                meta: true,
                method: 'get',
                path: `discover/sales-orders/${context.data.root_artkey}/products/collection-view`,
            },
            url_sync: false,
        }, undefined, transforms)

        this.watchers.push(watch(collection.filters.sellable_item_type, 'selection', (modelvalue) => {
            if (modelvalue) {
                const sellable = sellable_type({sellable_item_type: modelvalue} as any)
                if (sellable) {
                    m.route.set(`/sales/orders/${context.data.root_artkey}/view/manage/${sellable.entity}`)
                }
            }
        }))
    }

    onremove() {
        this.watchers.map((unwatch) => unwatch())
    }

    view(): m.Children {
        return [
            <PanelFilters className="context-create" collection={collection}/>,
            <CollectionView className="context-create" mode="table">
                <ManageButtons collection={collection}/>
                <CollectionHeader collection={collection} columns={columns}/>
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    on_row_click={(row) => {
                        select_row(row)
                        fetch_lots(row.case_artkey)
                    }}
                    row_actions={(row: GetSalesOrderItemsCollectionViewResponse) => {
                        return [
                            <Button
                                icon="success"
                                tip={'Select this product'}
                                onclick={() => {
                                    select_row(row)
                                    fetch_lots(row.case_artkey)
                                }}
                                type="info"
                                variant="toggle"
                            />,
                        ]
                    }}
                    row_detail={(row: GetSalesOrderItemsCollectionViewResponse) => {
                        if ([SalesOrderItemType.AdditionalItem, SalesOrderItemType.Voucher].includes(row.sales_order_item_type)) {
                            return null
                        }
                        return [
                            <CaseInfoPanel
                                bottle_artkey={row.bottle_artkey}
                                ignore_ref={true}
                            />,
                            <div className="columns">
                                <div className="column is-12">
                                    <OfferHistory
                                        bottle_artkey={row.bottle_artkey}
                                        customs_status={row.case_customs_status}
                                    />
                                </div>
                            </div>,
                            <div className="columns">
                                <div className="column is-12">
                                    <BottleSalesOrders
                                        bottle_artkey={row.bottle_artkey}
                                        customs_status={row.case_customs_status}
                                        current_client_artkey={context.data.sales_order.supplier_artkey}
                                    />
                                </div>
                            </div>,
                        ]
                    }}
                    row_separator={(row: GetSellableItemCollectionViewResponse, index: number, items: GetSalesOrderItemsCollectionViewResponse[]) => {
                        if (collection.state.query.filters.sellable_item_type !== SellableItemFilterType.TBOMarket) {
                            return null
                        }
                        if (index === 0 && !row.artkey.startsWith('SPLI')) return {
                            icon: 'tbo',
                            type: 'surface',
                            text: 'TBO',
                        }

                        const prev_item = items[index - 1]
                        if (!prev_item?.artkey.startsWith('SPLI') && row.artkey.startsWith('SPLI')) {
                            return {
                                icon: 'market',
                                type: 'surface',
                                text: 'Market',
                            }
                        }

                        return null
                    }}
                    row_status={(row) => {
                        const sellable = sellable_type(row)
                        return {
                            render: [
                                <Icon
                                    name={sellable.icon}
                                    tip={sellable.tip}
                                    type={sellable.type}
                                />,
                            ],
                            type: sellable.type,
                        }
                    }}
                />
            </CollectionView>,
        ]
    }
}
