import * as Sentry from '@sentry/browser'
import m from 'mithril'
import {classes, copy_object, merge_deep} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Dialog, FieldUpload, FieldText, FieldTextArea} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {invalid_fields, invalid_fields_format, required, reset_validation, validation} from '@bitstillery/common/lib/validation'
import {$s, $t, api, notifier} from '@bitstillery/common/app'

export class Issues extends MithrilTsxComponent<any> {

    data = proxy({
        $f: {
            description: '',
            sentry_replay_id: '',
            media: {
                data: null,
                name: '',
                content_type: '',
            },
            title: '',
            url: `${window.location.origin}#!${m.route.get()}`,
        },
        issue_url: false,
        loading: false,
    })

    initial_state = copy_object(this.data)

    $v = {
        description: validation([this.data.$f, 'description'], required()),
        media_data: validation([this.data.$f.media, 'data'], required()),
        url: validation([this.data.$f, 'url'], required()),
        title: validation([this.data.$f, 'title'], required()),
    }

    reset_form() {
        merge_deep(this.data, this.initial_state)
        reset_validation(this.$v)
    }

    view(_vnode: m.Vnode<any>) {
        window.reset_form = this.reset_form.bind(this)

        return <Dialog
            className={classes('c-issues', {
                hidden: $s.issues.collapsed,
            })}
            persistent={false}
            onclose={() => {
                $s.issues.collapsed = !$s.issues.collapsed
            }}
            title={this.data.issue_url ? $t('issues.label.title_submitted') : $t('issues.label.title')}
        >
            {this.data.issue_url ? <div className="body thanks">
                <img class="team" src="/common/img/team-bitstillery.jpg"/>
                <p>
                    Thank you for submitting your issue report! You can check the status of
                    your issue in our <a href={this.data.issue_url} target="_blank">issue tracker</a>.
                    Feel free to add related comments in the issue; either to provide additional context or
                    just if you would like some additional clarifications. We make Discover & Portal
                    better one step at a time, thanks to your help!
                </p>
            </div> : <div className="body submit">
                <FieldText
                    help={$t('issues.field.help.title')}
                    label={$t('issues.field.label.title')}
                    model={[this.data.$f, 'title']}
                    placeholder={$t('issues.field.placeholder.title')}
                    validation={this.$v.title}
                />

                <FieldText
                    help={$t('issues.field.help.url')}
                    label={$t('issues.field.label.url')}
                    model={[this.data.$f, 'url']}
                    placeholder={$t('issues.field.placeholder.url')}
                    validation={this.$v.url}
                />

                <FieldUpload
                    accept="image/*,video/*"
                    disabled={[$s.issues, 'collapsed']}
                    help={$t('issues.field.help.media')}
                    label={$t('issues.field.label.media')}
                    model={this.data.$f.media}
                    onbefore_media={() => {
                        $s.issues.collapsed = true
                    }}
                    onafter_media={() => {
                        $s.issues.collapsed = false
                    }}
                    placeholder={$t('issues.field.placeholder.media')}
                    type="screenshot"
                    validation={this.$v.media_data}
                />

                <FieldTextArea
                    label={$t('issues.field.label.description')}
                    model={[this.data.$f, 'description']}
                    help={$t('issues.field.help.description')}
                    placeholder={$t('issues.field.placeholder.description')}
                />

                <Button
                    disabled={this.data.loading || !!Object.values(this.$v).filter((i) => i && i._invalid).length}
                    icon="save"
                    loading={this.data.loading}
                    onclick={async() => {
                        const replay = Sentry.getReplay()
                        if (replay) {
                            await replay.flush()
                            this.data.$f.sentry_replay_id = replay.getReplayId() || ''
                        }
                        this.data.loading = true
                        const {result, status_code} = await api.post('discover/issues', this.data.$f, true)
                        if (status_code > 299) {
                            notifier.notify(`Er is een probleem met het aanmaken van dit issue: ${result.detail}`, 'danger')
                        }
                        if (status_code === 201) {
                            this.data.issue_url = result.web_url
                            setTimeout(this.reset_form.bind(this), 10000)
                            // Toon een bedankje voor het indienen en sluit weer af.
                        }
                        this.data.loading = false
                    }}
                    text={$t('issues.button.label.submit_issue')}
                    tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                    type="info"
                />
            </div>}
        </Dialog>
    }
}
