import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'
import {Icon} from '@bitstillery/common/components'

interface ClipboardCopyAttrs {
    text: string
    label?: string
}

/**
 * A clipboard copy functionality, that copies the provided text to the clipboard of the user.
 *
 */
export class ClipboardCopy extends MithrilTsxComponent<ClipboardCopyAttrs> {
    view(vnode: m.Vnode<ClipboardCopyAttrs>): m.Children {
        return (
            <span
                class="clipboard-copy no-click"
                onclick={() => {
                    navigator.clipboard
                        .writeText(vnode.attrs.text)
                        .then(() =>
                            notifier.notify(`Copied ${vnode.attrs.label || `"${vnode.attrs.text}"`} to clipboard.`, 'info'),
                        )
                        .catch(() => {
                            notifier.notify('Copying failed. Please inform the IT team.', 'danger')
                            // eslint-disable-next-line no-console
                            console.error('Copying failed')
                        })
                }}
            >
                <Icon name="copy" size="xs" className="ml-05" type="info" />
            </span>
        )
    }
}
