import m from 'mithril'
import {
    Button,
    FieldProduct,
    FieldText,
} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    invalid_fields,
    invalid_fields_format,
} from '@bitstillery/common/lib/validation'
import {focus_field} from '@bitstillery/common/lib/utils'

import {collection, select_row} from '@/sales/orders/view/lib/collection_sellable'
import {context, EntitySOCI, EntityType, methods} from '@/sales/orders/view/lib/context'
import {OrderSummary} from '@/sales/orders/view/manage/components/order_summary'

export class ContextCreditItem extends MithrilTsxComponent<unknown> {

    view(_vnode: m.Vnode<unknown, this>) {
        const entity = context.data.entities.SOCI as EntitySOCI
        const $v = context.$v.SOCI

        const invalid = invalid_fields($v)
        return <div className="c-manage-credit-item sales orders">
            <FieldProduct
                collection={collection}
                context={context}
                entity={entity}
                entity_type={EntityType.SOCI}
                filter={collection.filters.search}
                linked={entity.artkey || entity.source_artkey} // Sellable or entity selected
                locked={entity.artkey} // Lock existing entities
                select_row={select_row}
                tabindex={24}
            />

            <FieldText
                disabled={context.data.entity_type !== 'SOCI' && !context.data.entities.SOCI.artkey}
                type="number"
                label="Cases"
                help={entity.artkey ? `The number of cases to credit (max: ${entity.number_of_cases_available})` : 'Number of cases to credit'}
                max={entity.number_of_cases_available}
                min={1}
                model={[entity, 'number_of_cases']}
                placeholder="Number of cases..."
                tabindex={25}
                validation={$v.number_of_cases}
            />

            <Button
                className="btn-submit"
                disabled={invalid.length}
                icon="save"
                onclick={async() => {
                    await methods.upsert_entity(EntityType.SOCI, {
                        number_of_cases: entity.number_of_cases,
                        sales_order_item_artkey: entity.sales_order_item_artkey,
                    })
                    focus_field(24)
                } }
                text={context.data.entity_artkey ? `Update ${entity.title}` : `Add ${entity.title}`}
                tip={() => invalid_fields_format(invalid_fields($v), 'tip')}
                tabindex={26}
                type="success"
            />

            {!entity.artkey && <OrderSummary />}
        </div>
    }
}
