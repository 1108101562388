import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Amount, ClipboardCopy, CollectionHero, Country, DataCard, Link, Tippy} from '@bitstillery/common/components'
import {AccountSlug} from '@bitstillery/common/account/account'
import {api, config} from '@bitstillery/common/app'
import {format_iso_to_date, format_iso_to_date_time, titleize} from '@bitstillery/common/lib/format'
import {DateTime} from 'luxon'

import {accountIcon} from '@/accounts'
import {context} from '@/sales/orders/view/lib/context'
import {InlineEditableText, Percent} from '@/components/html_components'
import {is_origin_loendersloot} from '@/factserver_api/sales_api'

export class OrdersViewCollectionHero extends MithrilTsxComponent<any> {

    view(vnode: m.Vnode<any>): m.Children {
        const sales_order = context.data.sales_order
        const origin_loendersloot = is_origin_loendersloot(sales_order)

        const sales_order_portal_link =
        sales_order.account_slug === AccountSlug.ETR
            ? `${config.a2bc_portal_base_url}#!/orders/${sales_order.artkey}`
            : `${config.portal_base_url}#!/orders/${sales_order.artkey}`

        const planned_loading_time = sales_order.planned_loading_date
            ? DateTime.fromISO(sales_order.planned_loading_date).toISOTime()
            : null

        return <CollectionHero className={vnode.attrs.className}>
            <div className="memo-section default">
                <div className="memo-header">
                    Order details
                </div>

                <DataCard
                    className="memo-content"
                    model={{
                        data: [
                            {
                                label: 'Created',
                                value: (() => {
                                    let source
                                    if (sales_order.was_handled_by_profile_name === 'Portal') {
                                        source = <Link href={sales_order_portal_link} target="_blank">Portal</Link>
                                    } else {
                                        source = sales_order.was_handled_by_profile_name
                                    }

                                    return <span>{format_iso_to_date_time(sales_order.created_on)} by {source} <ClipboardCopy text={sales_order_portal_link} /></span>
                                })(),
                            },
                            {
                                label: 'Relation',
                                value: [
                                    <Country className="mr-05" country_code={context.data.relation.country_code?.toLowerCase() ?? ''}/>,
                                    <Link href={`/crm/relations/${context.data.sales_order.supplier_artkey}/edit`}>{context.data.sales_order.supplier_name}</Link>,
                                ],
                            },
                            {
                                label: 'Reference',
                                value: [
                                    <Link href={`/crm/relations/${sales_order.supplier_artkey}/edit`}>
                                        {accountIcon({
                                            slug: sales_order.account_slug,
                                            name: sales_order.account_name,
                                        })}
                                        {sales_order.reference}
                                    </Link>,
                                    <ClipboardCopy text={sales_order.reference} />,
                                ],
                            },

                            {label: 'Incoterm', value: sales_order.incoterm_and_location},
                            ...(sales_order.is_part_of_buy_from_account || context.data.buy_from_account.purchase_orders.length || context.data.buy_from_account.sales_orders.length ? [{
                                label: 'Buy from account',
                                value: <div>
                                    {context.data.buy_from_account.sales_orders.map((so) =>
                                        <div>
                                            <Link href={`/sales/orders/${so.artkey}/view/manage`} account={so.account_slug}>
                                                {so.reference}{` (${so.sales_order_status})`}
                                                {' '}
                                                {accountIcon({
                                                    slug: so.account_slug || '',
                                                    name: so.account_name || '',
                                                })}
                                            </Link>
                                        </div>,
                                    )}
                                    {context.data.buy_from_account.purchase_orders.map((po) =>
                                        <div>
                                            <Link href={`/purchase-orders/manage/${po.artkey}`} account={po.account_slug}>
                                                {po.reference}{` (${po.status})`}
                                                {' '}
                                                {accountIcon({
                                                    slug: po.account_slug || '',
                                                    name: po.account_name || '',
                                                })}
                                            </Link>
                                        </div>,
                                    )}
                                    {sales_order.is_part_of_buy_from_account &&
                                <div>
                                    Part of {' '}
                                    <Link
                                        href={`/sales/orders/${sales_order.is_part_of_buy_from_account_sales_order_artkey}/view/manage`}
                                        account={sales_order.is_part_of_buy_from_account_sales_order_account_slug}>
                                        {sales_order.is_part_of_buy_from_account_sales_order_reference}
                                        {' '}
                                        {accountIcon({
                                            slug: sales_order.is_part_of_buy_from_account_sales_order_account_slug || '',
                                            name: '',
                                        })}
                                    </Link>
                                </div>
                                    }
                                </div>,
                            }] : []),
                            {label: 'Insurance', value: sales_order.insurance_type_name},

                            {label: 'Sales order memo', value: <InlineEditableText
                                model={[context.data.sales_order, 'remark']}
                                onsave={async() => {
                                    await api.post(`discover/sales-orders/${context.data.root_artkey}/update-remark`, {
                                        remark: context.data.sales_order.remark,
                                    }, true)
                                }}
                                oncancel={(original_text: string | null) => context.data.sales_order.remark = original_text}
                            />},
                        ]}}
                />
            </div>

            <div className="memo-section default">
                <div className="memo-header">
                    Order figures
                </div>

                <DataCard
                    className="memo-content"
                    model={{
                        data: [
                            {label: 'Cases', value: sales_order.number_of_confirmed_cases},
                            {
                                label: 'Turnover',
                                value: <span>
                                    <Amount
                                        amount={sales_order.turnover}
                                        currency={sales_order.was_sold_in}
                                        rate={sales_order.sold_against_rate}
                                    />
                                &nbsp;
                                    <Tippy content={'The turnover contains the product total excl excise, plus additionals of type revenue and discount.'}>
                                        <span className={'glyphicon glyphicon-info-sign'} />
                                    </Tippy>
                                </span>,
                            },
                            {
                                label: 'Grand total',
                                value: <span>
                                    <Amount
                                        amount={sales_order.was_sold_for_incl_vat}
                                        currency={sales_order.was_sold_in}
                                        rate={sales_order.sold_against_rate}
                                    />
                                </span>,
                            },
                            {
                                label: 'Margin',
                                value: <Amount
                                    amount={sales_order.margin}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />,
                            },
                            {
                                label: 'Margin (%)',
                                value: <Percent value={+sales_order.margin_percentage / 100} />,
                            },
                        ]}}
                />
            </div>

            <div className="memo-section default">
                <div className="memo-header">Loendersloot</div>
                <DataCard
                    className="memo-content"
                    model={{
                        data: [
                            ...(origin_loendersloot && sales_order.sent_to_loendersloot_on ? [{
                                label: 'Sent to Loendersloot',
                                value: format_iso_to_date_time(sales_order.sent_to_loendersloot_on),
                            }] : []),
                            ...(origin_loendersloot && sales_order.warehouse_reference ? [{
                                label: 'Warehouse reference',
                                value: <span>
                                    {sales_order.warehouse_reference}
                                    <ClipboardCopy text={sales_order.warehouse_reference} />
                                </span>,
                            }] : []),
                            ...(origin_loendersloot && sales_order.warehouse_status ? [{
                                label: 'Warehouse status',
                                value: `${titleize(sales_order.warehouse_status || '')} - ${format_iso_to_date_time(sales_order.last_warehouse_message)}`,
                            }] : []),
                            ...(origin_loendersloot && sales_order.sent_pre_advice_to_loendersloot_on ? [{
                                label: 'Sent pre-advice on',
                                value: format_iso_to_date_time(sales_order.sent_pre_advice_to_loendersloot_on),
                            }] : []),

                            ...(origin_loendersloot && sales_order.sent_to_loendersloot_on ? [{
                                label: 'Estimated loading date',
                                value: format_iso_to_date(sales_order.estimated_loading_date),
                            }] : []),
                            ...(origin_loendersloot ? [{
                                label: 'Planned loading date',
                                value: planned_loading_time
                                    ? (planned_loading_time.startsWith('00:00:00')
                                        ? format_iso_to_date(sales_order.planned_loading_date)
                                        : format_iso_to_date_time(sales_order.planned_loading_date))
                                    : '-',
                            }] : []),
                        ],
                    }}
                />
            </div>

            <div className="memo-section default">
                <div className="memo-header">
                    <span className="mr-1">Transport</span>
                </div>
                <div className="memo-content">
                    <DataCard
                        className="memo-content"
                        model={{
                            data: [
                                {
                                    label: 'Send to Green',
                                    value: sales_order.delivery_external_reference
                                        ? `${sales_order.delivery_external_reference} - ${sales_order.delivery_status || ''}`
                                        : '-',
                                },
                                ...(origin_loendersloot && sales_order.pre_advice_reference ? [{
                                    label: 'Pre-advice',
                                    value: <span>
                                        {sales_order.pre_advice_reference}
                                        <ClipboardCopy text={sales_order.pre_advice_reference} />
                                    </span>,
                                }] : []),
                                ...(origin_loendersloot && sales_order.pre_advice_cancelled_on ? [{
                                    label: 'Pre-advice cancelled',
                                    value: format_iso_to_date_time(sales_order.pre_advice_cancelled_on),
                                }] : []),
                                ...(origin_loendersloot && sales_order.pre_advice_confirmed_on ? [{
                                    label: 'Pre-advice confirmed',
                                    value: format_iso_to_date_time(sales_order.pre_advice_confirmed_on),
                                }] : []),

                                {
                                    label: 'Pick-up date Green',
                                    value: <span>{format_iso_to_date(sales_order.delivery_pickup_timeslot_from)}</span>,
                                },
                            ],
                        }}
                    />
                </div>
            </div>
        </CollectionHero>
    }
}
