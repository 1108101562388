import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {DataCard, Icon} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/lib/specs'
import {format_money_with_symbol} from '@bitstillery/common/lib/format'
import {classes} from '@bitstillery/common/lib/utils'

import {collection} from '@/sales/orders/view/lib/collection_sales_order'
import {context} from '@/sales/orders/view/lib/context'
import {
    GetSalesOrderItemsCollectionViewResponse,
    SalesOrderItemType,
} from '@/factserver_api/fact2server_api'

export class OrderSummary extends MithrilTsxComponent<any> {

    view(vnode: m.Vnode<any>): m.Children {
        const total_number_of_cases = context.data.sales_order.number_of_confirmed_cases
        const total_eur = context.data.sales_order.product_total_excl_excise
        const number_of_lines = collection.state.total

        return <div className={classes('c-order-summary', vnode.attrs.className)}>
            <div className="break-line">
                <Icon className="quantity-type-icon" name="cartRight" type="unset" />
                Summary
            </div>
            <DataCard
                model={{
                    data: [
                        {label: 'Number of cases', value: total_number_of_cases},
                        {label: 'Total turnover', value: total_eur},
                        {label: 'Number of order lines', value: number_of_lines},
                    ],
                }}
            />

            <div className="break-line">
                <Icon className="quantity-type-icon" name="cartRight" type="unset" />
                Your order content ({context.data.sales_order_items_summary.length} items)
            </div>
            <div className="sales-order-items-summary">
                {context.data.sales_order_items_summary.map((i: GetSalesOrderItemsCollectionViewResponse) => {
                    return <div className="product-line">
                        <Icon
                            className="quantity-type-icon"
                            name="case"
                            size="s"
                            type={(() => {
                                if (i.sales_order_item_type === SalesOrderItemType.SalesOrderItem) {
                                    return 'success'
                                } else if (i.sales_order_item_type === SalesOrderItemType.CreditItem) {
                                    return 'warning'
                                }

                                return 'default'
                            })()}
                        />
                        <div className="quantity">{i.number_of_cases}</div>
                        <div className="product-name">
                            <div className="group">
                                <div className="name">{i.product_name}</div>
                                <div className="specs">{to_specs({
                                    bottle_alcohol_percentage: i.alcohol_percentage,
                                    bottle_refill_status: i.refill_status,
                                    bottle_volume: i.volume,
                                    case_number_of_bottles: i.number_of_bottles,
                                    case_gift_box_type: i.gift_box_type || '',
                                    case_customs_status: i.customs_status,
                                    case_tax_label: i.tax_label,
                                })}</div>
                            </div>
                        </div>
                        <div className="costs">{format_money_with_symbol(i.price_per_case * i.number_of_cases, context.data.sales_order.was_sold_in)}</div>
                    </div>
                })}
            </div>
        </div>
    }
}
