import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {ClipboardCopy, DataCard, Icon, Link, RatingStars} from '@bitstillery/common/components'
import {format_iso_to_date, format_iso_to_date_time, titleize} from '@bitstillery/common/lib/format'
import {proxy} from '@bitstillery/common/lib/proxy'
import {api, notifier} from '@bitstillery/common/app'
import {Amount, Timeline} from '@bitstillery/common/components'

import {context, methods} from '@/sales/orders/view/lib/context'
import {accountIcon} from '@/accounts'
import {is_origin_loendersloot, SalesApi} from '@/factserver_api/sales_api'
import {
    GetBuyFromAccountPurchaseOrderResponse,
    GetBuyFromAccountSalesOrderResponse, GetSalesOrderResponse,
    SalesOrderRatingResponse,
} from '@/factserver_api/fact2server_api'

export interface DetailsAttrs {
    sales_order: GetSalesOrderResponse
    buy_from_account_sales_orders_response: GetBuyFromAccountSalesOrderResponse[] | null
    buy_from_account_purchase_orders_response: GetBuyFromAccountPurchaseOrderResponse[] | null
}

export class ContextDataCard extends MithrilTsxComponent<DetailsAttrs> {

    sales_order_api = new SalesApi()

    data = proxy({
        has_feedback: false,
        feedback_loading: false,
        feedback: {
            closed: false,
            comment: '',
            loading: true,
            rating: 0,
        },
    })

    async fetch_ratings(artkey: number) {
        const {result} = await api.get<SalesOrderRatingResponse>(`discover/sales-orders/${artkey}/rating`)
        if (result.rating) {
            Object.assign(this.data.feedback, {
                comment: result.comment || '',
                loading: false,
                rating: result.rating,
            })
            this.data.feedback.loading = false
            this.data.feedback.rating = result.rating
            this.data.feedback.comment = result.comment || ''
        }
    }

    update_remark(vnode: m.Vnode<DetailsAttrs>): void {
        this.sales_order_api.update_sales_order_remark(vnode.attrs.sales_order.artkey, vnode.attrs.sales_order.remark).subscribe({
            next: () => {
                m.redraw()
            },
            error: () => {
            },
        })
    }

    view(vnode: m.Vnode<DetailsAttrs>): m.Children {
        const sales_order = vnode.attrs.sales_order
        const origin_loendersloot = is_origin_loendersloot(sales_order)

        return <div className="c-manage-data-card sales orders">
            <div className="memo-section default">
                <div className="memo-header">Order details</div>

                <DataCard
                    className="memo-content"
                    model={{
                        data: [
                            ...(sales_order.is_part_of_buy_from_account || context.data.buy_from_account.purchase_orders.length || context.data.buy_from_account.sales_orders.length ? [{
                                label: 'Buy from account',
                                value: <div>
                                    {context.data.buy_from_account.sales_orders.map((so) =>
                                        <div>
                                            <Link href={`/sales/orders/${so.artkey}/view/manage`} account={so.account_slug}>
                                                {so.reference}{` (${so.sales_order_status})`}
                                                {' '}
                                                {accountIcon({
                                                    slug: so.account_slug || '',
                                                    name: so.account_name || '',
                                                })}
                                            </Link>
                                        </div>,
                                    )}
                                    {context.data.buy_from_account.purchase_orders.map((po) =>
                                        <div>
                                            <Link href={`/purchase-orders/manage/${po.artkey}`} account={po.account_slug}>
                                                {po.reference}{` (${po.status})`}
                                                {' '}
                                                {accountIcon({
                                                    slug: po.account_slug || '',
                                                    name: po.account_name || '',
                                                })}
                                            </Link>
                                        </div>,
                                    )}
                                    {sales_order.is_part_of_buy_from_account &&
                                        <div>
                                            Part of {' '}
                                            <Link
                                                href={`/sales/orders/${sales_order.is_part_of_buy_from_account_sales_order_artkey}/view/manage`}
                                                account={sales_order.is_part_of_buy_from_account_sales_order_account_slug}>
                                                {sales_order.is_part_of_buy_from_account_sales_order_reference}
                                                {' '}
                                                {accountIcon({
                                                    slug: sales_order.is_part_of_buy_from_account_sales_order_account_slug || '',
                                                    name: '',
                                                })}
                                            </Link>
                                        </div>
                                    }
                                </div>,
                            }] : []),
                            {label: 'Sales manager', value: sales_order.supplier_sales_manager_profile_name},
                            {label: 'Status', value: sales_order.combined_status},
                            ...(sales_order.is_invoiced_on ? [
                                {label: 'Invoiced on', value: format_iso_to_date(sales_order.is_invoiced_on)},
                                {label: 'Invoice number', value: sales_order.invoice_number},
                                {label: 'Sent to FIS on', value: format_iso_to_date(sales_order.was_sent_to_financial_information_system_on)},
                            ] : []),

                            {label: 'Currency', value: sales_order.was_sold_in},
                            ...(+sales_order.sold_against_rate !== 1 ? [{
                                label: 'Exchange rate',
                                value: sales_order.sold_against_rate,
                            }] : []),
                            this.data.feedback.rating && {
                                label: 'Rating',
                                value: this.data.feedback.rating
                                    ? <div className="fl">
                                        <RatingStars
                                            model={this.data.feedback}
                                            read_only={true}
                                            size="s"
                                        />
                                        {!!this.data.feedback.comment && <Icon
                                            className="ml-05"
                                            name="info"
                                            size="s"
                                            tip={() => this.data.feedback.comment}
                                            type="info"
                                        />}
                                    </div>
                                    : '-',
                            },
                        ]}}
                />
            </div>

            <div className="memo-section default">
                <div className="memo-header">Order Figures</div>

                <DataCard
                    className="memo-content"
                    model={{
                        data: [
                            {
                                label: 'Total purchased value',
                                value: <Amount amount={sales_order.euro_purchasing_value} currency={'EUR'} />,
                            },
                            {
                                label: 'Product excl. excise',
                                value: <Amount
                                    amount={sales_order.product_total_excl_excise}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />,
                            },
                            {
                                label: 'Order excl. excise',
                                value: <Amount
                                    amount={sales_order.was_sold_for_excl_excise}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />,
                            },
                            {
                                label: 'Excise',
                                value: <Amount
                                    amount={sales_order.excise_total}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />,
                            },
                            {
                                label: 'Order incl. excise',
                                value: <Amount
                                    amount={sales_order.was_sold_for}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />,
                            },
                            {
                                label: 'VAT',
                                value: <Amount
                                    amount={sales_order.vat_total}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />,
                            },
                            {
                                label: 'Grand Total',
                                value: <Amount
                                    amount={sales_order.was_sold_for_incl_vat}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />,
                            },
                        ],
                    }}
                />
            </div>

            <div className="memo-section default">
                <div className="memo-header">Loendersloot</div>
                <DataCard
                    className="memo-content"
                    model={{
                        data: [
                            {
                                label: 'Req. delivery date',
                                value: sales_order.requested_delivery_date
                                    ? format_iso_to_date(sales_order.requested_delivery_date)
                                    : 'As soon as possible',
                            },
                            ...(origin_loendersloot && sales_order.pre_advice_reference ? [{
                                label: 'Pre-advice',
                                value: <span>
                                    {sales_order.pre_advice_reference}
                                    <ClipboardCopy text={sales_order.pre_advice_reference} />
                                </span>,
                            }] : []),
                            ...(origin_loendersloot && sales_order.pre_advice_cancelled_on ? [{
                                label: 'Pre-advice cancelled',
                                value: format_iso_to_date_time(sales_order.pre_advice_cancelled_on),
                            }] : []),
                            ...(origin_loendersloot && sales_order.pre_advice_confirmed_on ? [{
                                label: 'Pre-advice confirmed',
                                value: format_iso_to_date_time(sales_order.pre_advice_confirmed_on),
                            }] : []),
                            ...(origin_loendersloot && sales_order.sent_to_loendersloot_on ? [{
                                label: 'Sent to loendersloot on',
                                value: format_iso_to_date_time(sales_order.sent_to_loendersloot_on),
                            }] : []),
                            ...(origin_loendersloot && sales_order.warehouse_reference ? [{
                                label: 'Warehouse reference',
                                value: <span>
                                    {sales_order.warehouse_reference}
                                    <ClipboardCopy text={sales_order.warehouse_reference} />
                                </span>,
                            }] : []),
                            ...(origin_loendersloot && sales_order.warehouse_status ? [{
                                label: 'Warehouse status',
                                value: `${titleize(sales_order.warehouse_status || '')} - ${format_iso_to_date_time(sales_order.last_warehouse_message)}`,
                            }] : []),
                        ],
                    }}
                />
            </div>

            <div className="memo-section default">
                <div className="memo-header">
                    Transport
                </div>
                <div className="memo-content">
                    <DataCard
                        className="memo-content"
                        model={{
                            data: [
                                {label: 'Incoterm', value: sales_order.incoterm_and_location},
                                {label: 'Origin', value: sales_order.origin_name || '-'},
                                {label: 'Destination', value: sales_order.destination_location || sales_order.destination_name},
                                {
                                    label: 'Req. delivery date',
                                    value: sales_order.requested_delivery_date
                                        ? format_iso_to_date(sales_order.requested_delivery_date)
                                        : 'As soon as possible',
                                },
                                ...(origin_loendersloot && sales_order.pre_advice_reference ? [{
                                    label: 'Pre-advice',
                                    value: <span>
                                        {sales_order.pre_advice_reference}
                                        <ClipboardCopy text={sales_order.pre_advice_reference} />
                                    </span>,
                                }] : []),
                                ...(origin_loendersloot && sales_order.pre_advice_cancelled_on ? [{
                                    label: 'Pre-advice cancelled',
                                    value: format_iso_to_date_time(sales_order.pre_advice_cancelled_on),
                                }] : []),
                                ...(origin_loendersloot && sales_order.pre_advice_confirmed_on ? [{
                                    label: 'Pre-advice confirmed',
                                    value: format_iso_to_date_time(sales_order.pre_advice_confirmed_on),
                                }] : []),
                                {
                                    label: 'Weight & Pallets',
                                    value: <span>
                                        {sales_order.delivery_estimated_weight_in_kilos && <span>Weight: {sales_order.delivery_estimated_weight_in_kilos} kg </span>}
                                        {sales_order.delivery_estimated_number_of_pallets && <span>Pallets: {sales_order.delivery_estimated_number_of_pallets}</span>}
                                    </span>,
                                },
                            ],
                        }}
                    />
                </div>
            </div>

            <div className="memo-section default">
                <div className="memo-header">
                    Finance
                </div>
                <div className="memo-content">
                    <DataCard
                        className="memo-content"
                        model={{
                            data: [
                                {label: 'Sent to FIS', value: format_iso_to_date_time(sales_order.was_sent_to_financial_information_system_on)},
                                {label: 'Includes excise', value: sales_order.includes_excise ? 'Yes' : 'No'},
                                {label: 'Relation ledger', value: sales_order.relation_sales_ledger_description},
                                {label: 'Invoiced Ledger', value: sales_order.sales_ledger_description || '-'},
                                {label: 'Invoiced excise Ledger', value: sales_order.excise_ledger_description || '-'},
                            ],
                        }}
                    />
                </div>
            </div>

            <div className="memo-section default">
                <div className="memo-header">
                    Customer Contact History
                </div>
                <div className="memo-content">
                    <Timeline
                        artkey={context.data.sales_order.supplier_artkey}
                        messages={context.data.relation_log_entries}
                        ondelete={async(message) => {
                            await api.delete(`discover/relations/${context.data.relation.artkey}/log-entries/${message.artkey}`, {}, true)
                            await methods.fetch_relation()
                            notifier.notify('Memo removed', 'info')
                        }}
                        onsave={async(message) => {
                            await api.post(`discover/relations/${context.data.relation.artkey}/log-entries`, {
                                log_type: 'MESSAGE',
                                message,
                            }, true)
                            notifier.notify('Memo added', 'info')
                            await methods.fetch_relation()
                        }}
                    />
                </div>
            </div>

        </div>
    }
}
